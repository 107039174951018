.contact_us {
    padding-top: 152px;
    @media screen and (max-width: 925px) {
      padding-top: 125px;
  }
  @media screen and (max-width: 425px) {
      padding-top: 111px;
  }
    .contact_data {
      padding: 50px 30px;
      display: flex;
      justify-content: space-between;
        @media screen and (max-width: 1040px) {
            flex-direction: column;
          }
      .widget_contacts {
        height: 500px;
        width: 30%;
        border-top: 1px solid #6147aa96;
        border-bottom: 1px solid #6147aa96;
        border-left: 1px solid #6147aa96;
        @media screen and (max-width: 1040px) {
          width: 100%;
          height: inherit;
          border-top: 1px solid #6147aa96;
        border-right: 1px solid #6147aa96;
        border-left: 1px solid #6147aa96;
        border-bottom: none;
        }
        ul {
  
          list-style-type: none;
          @media screen and (max-width: 1040px) {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
  
          }
          li {
            display: flex;
            align-items: center;
            padding-top: 25px ;
            @media screen and (max-width: 1040px) {
              display: flex;
              flex-direction: column;
            }
            .contact-text {
              font-size: 15px;
              padding: 20px;
              width: 80%;
              line-height: 25px;
              @media screen and (max-width: 1040px) {
                order: 1;
                text-align: center;
                width: 100%;
                  }
            strong{
              font-size: 20px;
              color: #494949;
              @media screen and (max-width: 768px) {
                font-size: 17px;
                  }
            }
              p{
              color: #535353;
              font-size: 17px;
              @media screen and (max-width: 768px) {
                font-size: 15px;
                  }
              a{
                color: #535353;
                text-decoration: none;
              }
            }
            }
            .icon {
              width: 40px;
              height: 40px;
              margin-right: 20px;
              @media screen and (max-width: 1040px) {
            order: 0;
            margin-right: 0;
              }
            }
          }
        }
      }
      .right_map {
        width: 75%;
        // padding-left: 30px;
          @media screen and (max-width: 1040px) {
            width: 100%;
          }
         
        iframe {
          width: 100%;
          border: 0;
          @media screen and (max-width: 768px) {
            height: 350px;
          }
          @media screen and (max-width: 525px) {
            height: 300px;
          }
          @media screen and (max-width: 350px) {
            height: 230px;
          }
        }
      }
    }
  }
  .contact_form {
    width: 50%;
    padding-bottom: 40px;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
    h1 {
      font-size: 25px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 30px;
      padding: 0 10px;
      text-align: center;
      color: #535353;
      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
      @media screen and (max-width: 350px) {
        font-size: 16px;
      }
    }
    form {
      padding-top: 25px;
      margin: 0 auto;
      display: flex;
      flex-flow: column nowrap;
      width: 60%;
      // padding: 0 30px 30px 0;
      @media screen and (max-width: 1500px) {
        width: 100%;
      }
      .input_part {
        position: relative;
  
        span {
          height: 26px;
          width: 26px;
          position: absolute;
          margin: 12px;
        }
        input {
          padding: 15px 15px 15px 50px;
          font-size: 16px;
          border-radius: 8px;
          margin: 0 0 20px 0;
          border: 1px solid rgba(0, 0, 0, 0.2);
          width: 100%;
        }
      }
      textarea {
        padding: 15px;
        font-size: 16px;
        border-radius: 8px;
        margin: 0 0 20px 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        resize: none;
        font-family: inherit;
      }
      .button{
        margin: 0 auto;
      }
    }
  }
  