
.currentServiceMain{
    padding-top: 152px;
    overflow-x: hidden;
}
.topPart{
    background: url(../../../assets/img/Plombavorum_Main_Img.png) 100% no-repeat,url(../../../assets/img/ServicePurpleImage.png)50% no-repeat,url(../../../assets/img/Vector\ 1.svg) 50% no-repeat;
    width: 100%;
    height: 822px;

}