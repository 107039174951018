@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}

.topPartHelp{
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    background-image: url(../../../../assets/img/helpMain.png);
    display: flex;
    z-index: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-y: hidden;
    padding-bottom: 25px;
    padding-top: 25px;


}



.centerPart{
    width: 46%;
    z-index: 111;
    margin-left: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceTextHelp{
    color: white;
    font-family: tahoma;
    font-weight: 700;
    font-size: 77px;
    letter-spacing: 3.5px;
}

.serviceMiniTextHelp{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    margin-top: 24px;
    
    color: #FFFFFF;
    width: 100%;
}

.serviceMiniTextFlexHelp{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.serviceButtonJoin{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 330px;
    height: 80px;
    border: none;
    margin-top: 49px;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #B498D2;

}

.fourSection{
    display: flex;
    margin-top: 45px;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.fourSection h2{
    font-family: 'Tahoma';
    width: 76%;
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    text-transform: uppercase;
    color: #7F7298;
    letter-spacing: 3.5px;
}
.fourSection span{
    width: 84%;
    margin-top: 30px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #888888;
}


.fiveSectionHelp{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    margin-left: 154px;
}
.fiveSectionHelp2{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    margin-left: 154px;
}
.fiveSectionMain1{
    background-color: #F8F8F8;
    padding: 15px;
    margin-top: 60px;
}
.fiveSectionMain2{
    background-color: #F3F1FA;
    padding: 15px;
    padding-bottom: 30px;
}
.grayHelp{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;

    color: #888888;

}

.moreGrayHelp{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;

    color: #626060;

}

.sixSectionHelp{
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.sixSectionHelp img{
    margin-left: 56px;
    border-radius: 25px;
}

.sixSectionHelpRightPart{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.sixSectionHelpRightPart h2{

    width: 50%;
    font-family: 'Tahoma';
font-style: normal;
font-weight: 700;
font-size: 77px;
line-height: 93px;
text-transform: uppercase;
    letter-spacing: 1.9px;
color: #7F7298;
}
.sixSectionHelpRightPart span{
    width: 71%;
    
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;

    color: #888888;

}
.sevenSectionHelp{
    margin-top: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sevenSectionBigText{
    width: 100%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    
    text-align: center;
    text-transform: uppercase;

    color: #7F7298;

}
.sevenSectionMiniText{
    font-family: 'Tahoma';
font-style: normal;
font-weight: 400;
font-size: 49px;
line-height: 59px;
text-align: center;

color: #C3ADD7;
}

.bottomPartSevenSectionHelp{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.sevenSectionBlocks{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
}

.sevenSectionBlocks span{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    width: 66%;
    color: #717171;
    margin-top: 20px;

}

.sevenSectionBlocks img{
    width: 86px;
    height: 86px
}
.sevenSectionBlocks p{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;

    color: #717171;
}

.mt213{
    margin-top: 72px;
}

.hrSectionHelpFlex{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
}

.hrSectionHelp{
    width: 80%;
    border: 1px solid #000000;
}

.eightSection{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 36px;

}

.eightSection span{
    width: 80%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #777777;
}

.sevenToEightmainBlock{
    background-color: #FAFAFA;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-top: 35px;
}
.nineSectionMain{
    background-color: #F8F8F8;
    padding-top: 75px;
    padding-bottom: 58px;
}

.nineSection{
    display: flex;
    align-items: center;
    flex-direction: column;

    justify-content: center;
}

.nineSection h2{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    text-transform: uppercase;

    color: #7F7298;
    letter-spacing: 2.5px;

}
.nineSection span{
    margin-top: 89px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    width: 80%;
    color: #888888;

}

@media (max-width:1603px){
    .sixSectionHelp img{
        width: 45%;
    }
    .sixSectionHelp{
        flex-direction: column;
        align-items: center;
    }
    .sixSectionHelpRightPart h2{
        width: 100%;
        text-align: center;
    }
    .sevenSectionBlocks span{
        font-size: 25px;
    }
    .sevenSectionBlocks p{
        font-size: 25px;
    }
    .fourSection span{
        font-size: 25px;
    }
    .moreGrayHelp{
        font-size: 25px;
    }
    .grayHelp{
        font-size: 25px;
    }
    .sixSectionHelpRightPart span{
        text-align: center;
        width: 100%;
        font-size: 25px;
    }
    .sevenSectionMiniText{
        font-size: 38px;
    }
    .sixSectionHelpRightPart{
        margin-left: 0px;
        margin-top: 57px;
    }
    .sixSectionHelpRightPart h2{
        font-size: 38px;
        line-height: 64px;
    }
    .sevenSectionBigText{
        font-size: 38px;
    }
    .bottomPartSevenSectionHelp{
        margin-top: 40px;
    }
    .nineSection span{
        margin-top: 57px;
        font-size: 25px;
    }
    .nineSection h2{
        font-size: 38px;
    }
    .fourSection h2{
        font-size: 38px;
    }
    .eightSection span{
        font-size: 25px;
    }

}


@media (max-width:1367px){
    .serviceTextHelp{
        font-size: 48px;
    }
    .serviceMiniTextHelp{
        font-size: 27px;
    }
    .centerPart{
        margin-left: 37px;
    }
}

@media (max-width:1251px){
    .fourSection h2{
        width: 100%;
    }
    .sixSectionHelpRightPart h2{
        width: 100%;
    }
}


@media (max-width:1047px){
    .topPartHelp{
        background-image: none;
        background-color: #D0A0CD;
    }
    .centerPart{
        width: 100%;
    }
    .fiveSectionHelp{
        margin-left: 0px;
        text-align: center;
    }
    .fiveSectionHelp2{
        margin-left: 0px;
        text-align: center;
    }
}


@media (max-width:1001px){
    .bottomPartSevenSectionHelp{
        flex-wrap: wrap;
    }
    .sevenSectionBlocks{
        width: 100%;
        margin-top: 35px;
    }
    .sixSectionHelp img{
        margin-left: 0px;
        width: 74%;
        margin-top: 35px;
    }
    .sixSectionHelp{
        flex-wrap: wrap;
        width: 100%;
    }
    .sixSectionHelpRightPart{
        margin-left: 0px;
        text-align: center;
    }
    .sixSectionHelpRightPart span{
        width: 100%;
    }
    .topPartHelp{
        margin-top: -19px;
    }
}



@media (max-width:430px){
    .topPartHelp{
        margin-top: -41px;
    }
}

@media (max-width:386px){
    .fourSection h2{
        font-size: 33px;
        line-height: 45px;
    }
    .sixSectionHelpRightPart h2{
        font-size: 33px;
    }
    .sevenSectionBigText{
        font-size: 29px;
        line-height: 47px;
    }
    .sevenSectionMiniText{
        font-size: 30px;
    }
    .nineSection h2{
        font-size: 35px;
        line-height: 45px;
    }
    .nineSection span{
        font-size: 28px;
        width: 100%;
    }
}