.blogs_page {
  padding-top: 152px;
  @media screen and (max-width: 925px) {
    padding-top: 125px;
  }
  @media screen and (max-width: 425px) {
    padding-top: 111px;
  }
}
.blog {
  padding: 70px 0;
  @media screen and (max-width: 860px) {
    padding: 30px 0;
  }
}

.blog__main {
  display: flex;
  // justify-content: space-between;

  @media screen and (max-width: 728px) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.blog__card {
  display: flex;
  flex-direction: column;
  width: 70%;
  @media screen and (max-width: 1260px) {
    width: 66%;
  }

  @media screen and (max-width: 728px) {
    width: 100%;
  }

  .blog__post {
    width: 88%;
    padding: 48px 0 40px 0;
    border-bottom: 1px solid #acacac;

    @media screen and (max-width: 860px) {
      padding: 30px 0;
    }

    @media screen and (max-width: 728px) {
      width: 100%;
    }

    .blog__post-img {
      margin-bottom: 10px;
      img {
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
      }
    }

    .blog__post-title {
      color: #535353;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0em;
      line-height: 1.42;
      text-decoration-line: none;
      -moz-text-decoration-line: none;

      @media screen and(max-width:1260px) {
        font-size: 33px;
      }

      @media screen and (max-width: 860px) {
        font-size: 25px;
      }
    }

    .blog__post-date {
      font-size: 20px;
    }

    span {
      display: block;
      margin: 10px 0;
      color: #535353;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0em;
      line-height: 1.74;

      @media screen and (max-width: 860px) {
        margin: 11px 0;
      }
    }

    p {
      display: block;
      margin-top: 10px;
      color: #535353;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0em;
      line-height: 1.74;

      @media screen and (max-width: 860px) {
        margin: 11px 0;
      }
    }
    // .button-read {
    //   a {
    //     text-decoration: none;
    //     display: inline-block;
    //     padding: 10px 20px;
    //     transition: all 0.3s ease;
    //     border: 1px solid #535353;
    //     // border-radius: 10px;
    //     color: #535353;
    //     font-size: 21px;
    //     font-weight: 300;
    //     @media screen and (max-width: 925px) {
    //       font-size: 17px;
    //     }
    //     @media screen and (max-width: 768px) {
    //       padding: 13px;
    //       font-size: 15px;
    //     }
    //     @media screen and (max-width: 550px) {
    //       padding: 8px;
    //       font-size: 10px;
    //     }
    //     &:hover {
    //       background: #535353;
    //       color: #fff;
    //     }
    //   }
    // }

    .button-link {
      color: #535353;
      font-weight: 600;
    }
  }
}

.blog__sidebar {
  width: 23%;

  @media screen and (max-width: 1260px) {
    width: 33%;
  }

  @media screen and (max-width: 728px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  h4 {
    color: #847299;
    font-size: 34px;
    font-weight: 500;
    padding-top: 50px;

    @media screen and (max-width: 860px) {
      font-size: 25px;
    }
  }

  ul {
    margin: 25px 0;
    padding-left: 25px;
  }

  ul > li {
    color: #535353;
    padding-bottom: 10px;
    list-style: circle;
    list-style-position: outside;
    font-size: 25px;

    @media screen and (max-width: 860px) {
      font-size: 20px;
    }
  }

  .blog__sidebar-link {
    color: #535353;
    font-size: 19px;
    font-weight: 500;
    border-bottom: 1px solid #535353;
    margin-right: 5px;
    text-decoration-line: none;
    -moz-text-decoration-line: none;
  }

  .blog__sidebar-img {
    margin: 25px 0;

    @media screen and (max-width: 728px) {
      width: 55%;
    }
  }

  @media screen and (max-width: 458px) {
    .blog__sidebar-btn:not(:last-child) {
      margin-right: 7px;
      margin-bottom: 7px;
    }

    .blog__sidebar-btn:last-child {
      margin-right: 0px;
      margin-bottom: 7px;
    }
  }
}

.blogPage {
  padding-top: 230px;
  @media screen and (max-width: 1000px) {
    padding-top: 170px;
  }
  // @media screen and ( max-width: 860px){
  //     padding: 30px 0;
  // }
  .blogPage__cont {
    display: flex;
    justify-content: space-between;
    color: #535353;

    @media screen and ( max-width: 897px){
      flex-wrap: wrap;
    }

    .blogPage__main {
      padding: 48px 30px 50px 0;
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0;
      }
      .blogPage__center {
        text-align: justify;
        p {
          color: #8f8f8f;
          line-height: 1.5;
          padding: 7px 0;
          font-size: 18px;
          text-align: left;
          @media screen and (max-width: 1080px) {
            font-size: 16px;
          }
          @media screen and (max-width: 470px) {
            font-size: 15px;
          }
        }
      }
      .blogPage-img {
        object-fit: cover;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }
      }

      h1 {
        font-size: 35px;
        line-height: 1.4;
        margin: 20px 0 16px;

        @media screen and ( max-width: 897px){
         text-align: center;
        }
      }

      span {
        font-size: 15px;
      }
    }
    
  }
}
.blogPage__sidebar {
  width: 350px;
  padding-top: 48px;

  @media screen and ( max-width: 897px){
    width: 100%;
  }
  h3 {
    font-size: 23px;
    color: #fff;
    background-color: #847299;
    padding: 20px 5px;
  }
  .sidebar_post {
    display: flex;
    margin: 7px 0;
    text-decoration: none;
    -moz-text-decoration:none;
    .post_img {
      height: 100px;
      min-width: 100px;
      width: 100px;
    }
    .post_title {
      font-size: 18px;
      color: #8f8f8f;
      padding: 0 10px;
      h4{
        padding-bottom: 10px;
       color: #535353;
       
      }
    }
  }
}


