header {
  width: 100%;
    .header {
      width: 100%;
      background-color: #847299;
    }
    .top-header {
      padding: 10px 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      strong.text {
        font-size: 20px;
        letter-spacing: 2px;
        font-weight: 500;
        @media screen and (max-width: 525px) {
          font-size: 15px;
        }
        @media screen and (max-width: 315px) {
          font-size: 13px;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        transition: all 0.3s ease;
        .icon {
          font-size: 25px;
          margin-left: 10px;
          @media screen and (max-width: 425px) {
            font-size: 20px;
          }
        }
      }
     
      .top_info_box {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        color: #fff;
        text-decoration: none;
        font-size: 23px;
        line-height: 25px;
        transition: all 0.3s ease;
        &:hover {
          color: #d4c4ff;
          transition: all 0.3s ease;
        }
      }
    }
    a:hover {
      color: #d4c4ff;
      transition: all 0.3s ease;
    }
  }
  
  header {
    position: fixed;
    width: 100%;
    z-index: 300;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 18px 19px -21px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    nav {
      display: flex;
      // flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // padding: 0 30px;
  
      .logo {
        height: 93px;
        max-width: 242px;
        margin: 5px 0;
        object-fit: cover;
        @media screen and (max-width: 1000px) {
          height: 70px;
          max-width: 182px;
        }
        @media screen and (max-width: 425px) {
          height: 55px;
          max-width: 142px;
        }
      }
      .nav_menu {
        display: flex;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
          top: 129px;
          width: 100vw;
          left: 0;
          position: absolute;
          background: rgb(255, 255, 255);
          transform: translateX(1550px);
          transition: 0.5s;
          padding-bottom: 20px;
        }
        @media screen and (max-width: 425px) {
          top: 111px;
        }
        .dropDownMenuLi:hover{
          .dropDownItems{
            display: flex;
          }
        }
        .serviceAndStrelka{
          display: flex;
          margin-top: -5px;

          @media screen and (max-width:1000px){
            width: 100%;
            justify-content: flex-end;
            align-items: center;
          }
          img{
            width: 10%;
            cursor: pointer;
            display: none;

            @media screen and (max-width:1000px){
              display: block;
              width: 18px;
              height: 18px;
            }
          }
          a{
            @media screen and (max-width:1000px){
              margin-right: 15px;
            }
          }
          .rotateStrelka{
            transition: .5s;
            transform: rotate(180deg);
          }
          .returnStrelka{
            transition: .5s;
            transform: rotate(0deg);
          }
        }
        .dropDownItems{
          position: absolute;
          width: 100%;
          height: 270px;
          display: none;
          flex-direction: column;
          background-color: #FFFFFF;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          @media screen and (max-width: 1000px) {
            position: relative;
          }

          a:hover{
            color: #C19EE0;
          }

          a{
            margin-left: 15px;
            font-size: 14px;
            margin-top: 15px;

            @media screen and (max-width: 1000px) {
              font-size: 16px;
            }
          }
          
          
        }
        li {
          list-style-type: none;
          flex-flow: column nowrap;
          padding: 20px 0 20px 35px;
          position: relative;
          @media screen and (max-width: 1000px) {
            text-align: end;
            padding: 10px 30px;
          }
          @media screen and (max-width: 414px) {
            padding: 5px 30px;
          }
          a {
            padding: 5px 0;
            color: #535353;
            -webkit-text-decoration-line: none;
                    text-decoration-line: none;
            font-size: 19px;
            letter-spacing: 1.3px;
            font-weight: 200;
            position: relative;
            @media screen and (max-width: 768px) {
              font-size: 17px;
            }
            @media screen and (max-width: 414px) {
              font-size: 15px;  
            }
            &::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 2px;
              bottom: -2px;
              background: transparent;
            }
          }
          &:hover > a {
            color: #6247aa;
            transition: all 0.3s ease;
            &::before {
              
              background: radial-gradient(
                100% 185.14% at 100% 100%,
                #c19ee0 0%,
                #6247aa 100%
              );
              -webkit-animation: animateLine 1s;
                      animation: animateLine 1s;
            }
          }
        }
      }
      .mobile_btn {
        .navicon {
          display: none;
          font-size: 24px;
          cursor: pointer;
          margin-left: 20px;
          color: #000;
          @media screen and (max-width: 1000px) {
            display: block;
            font-size: 40px;
          }
          @media screen and (max-width: 525px) {
            font-size: 30px;
          }
        }
      }
    }
  }
  header nav .nav_menu.active {
    transform: translateX(0px);
    z-index: 1;
  }
  @-webkit-keyframes animateLine {
    0% {
      width: 10%;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes animateLine {
    0% {
      width: 10%;
    }
    100% {
      width: 100%;
    }
  }
  