@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}

.topPart{
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    background-image: url(../../../../assets/img/gotovo.png);
    display: flex;
    z-index: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-y: hidden;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow-x: hidden;


}



.topPart::after{
    width: 100%;
    top: 0;
    height: 828px;
    content: none;
    position: absolute;
    overflow-y: hidden;
}

.topPart::before{
    width: 100%;
    content: none;
    position: absolute;
    overflow-y: hidden;
}

.centerPart{
    width: 40%;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceText{
    color: white;
    font-family: tahoma;
    font-weight: 700;
    font-size: 77px;
}

.serviceMiniText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    
    color: #FFFFFF;
    width: 100%;
}

.serviceMiniTextFlex{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-left: 185px;
}

.serviceButtonJoin{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 330px;
    height: 80px;
    border: none;
    margin-top: 49px;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #B498D2;

}

.thirdSectionBlocks{
    display: flex;
    flex-direction: column;
    width: 33%;
    align-items: center;
}
.thirdSectionBlocks img{
    width: 100px;
    height: 100px;
}
.thirdSectionBlocks span{
    margin-top: 40px;
}

.fourSectionMainBlock{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 45px;
    padding-bottom: 32px;
    background-color: #F8F8F8;
}

.fourSection{
    display: flex;
    margin-top: 45px;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.fourSection h2{
    font-family: 'Tahoma';
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    text-transform: uppercase;
    color: #7F7298;
    letter-spacing: 3.5px;
}
.fourSection span{
    width: 84%;
    margin-top: 30px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #888888;
}
.fourSectionSecondBlock{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 832px;
    align-items: center;
    background-color: #F8F8F8;
    margin-top: 59px;
}
.secondSection{
    display: flex;
    margin-top: 91px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

}

.secondSectionFirstText{
    width: 50%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    color: #7F7298;
    text-transform: uppercase;
    letter-spacing: 4.5px;

}
.secondSectionSecondText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 59px;
    text-align: center;
    width: 75%;
    color: #C3ADD7;
}
/* .contactButtonPlomb{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #7F7298;

} */

.thirdSection{
    width: 100%;
    display: flex;
    margin-top: 66px;
    justify-content: space-around;
}
.thirdSection span{
    font-family: 'Tahoma';
    width: 65%;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;

    color: #717171;
}

.thirdSectionButtonFlex{
    display: flex;
    width: 100%;
    justify-content: center;
}

.thirdSectionButtonFlex button{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 28%;
    height: 80px;
    border: none;
    color: #7F7298;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #7F7298;

}

@media (max-width:1601px){
    .topPart{
        height: 700px;
    }
    .serviceText{
        font-size: 46px;
    }
    .serviceMiniText{
        font-size: 25px;
    }

    .fourSection h2{
        font-size: 38px;
        letter-spacing: 1.5px;
    }
    .secondSectionFirstText{
        width: 100%;
        font-size: 38px;
    }
    .fourSection span{
        font-size: 25px;
    }
    .thirdSection span{
        font-size: 30px;
    }
}

@media (max-width:1374px){
    .topPart{
        height: 650px;
    }
    .fourSection span{
        font-size: 28px;
    }
    .secondSectionFirstText{
        font-size: 38px;
        width: 100%;
    }
    .serviceText{
        font-size: 46px;
    }
    .serviceMiniTextFlex{
        margin-left: 25px;
    }
    .centerPart{
        width: 53%;
    }
}

@media (max-width:1251px){
    .thirdSection{
        flex-wrap: wrap;
        margin-top: 10px;
    }
    .thirdSectionBlocks{
        width: 100%;
        margin-top: 45px;
    }
    .fourSectionSecondBlock{
        height: 100%;
        padding-bottom: 35px;
    }
    .secondSectionFirstText{
        width: 100%;
        line-height: 64px;
    }
}


@media (max-width:1110px){
    .topPart{
        background-image: none;
        background-color: #C29BC1;
        height: 100%;
        margin-top: -45px;
    }
    .centerPart{
        width: 100%;
    }
}

@media (max-width:975px){
    .fourSection h2{
        font-size: 35px;
        line-height: 45px;
    }
    .secondSectionFirstText{
        font-size: 35px;
    }
    .thirdSection span{
        font-size: 33px;
    }
}
@media (max-width:513px){
    .fourSection h2{
        font-size: 30px;
    }
}

@media (max-width:456px){
    .serviceMiniTextFlex{
        margin-left: -18px;
    }
    .serviceText{
        font-size: 35px;
    }
    .serviceMiniText{
        font-size: 28px;
    }
    .thirdSection span{
        font-size: 29px;
    }
    .serviceButtonJoin{
        width: 250px;
        font-size: 31px;
    }
    
}