

.flex_column{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.second_input{
    margin-top: 15px;
}

.image_uploaded_text{
    color: rgb(21, 224, 21);
    font-size: 18px;
}
.image_dont_uploaded{
    color: red;
    font-size: 18px;
}
.saveButton{
    cursor: pointer;
    margin-top: 25px;
}
.edit_input{
    height: 40px;
    margin-top: 15px;
}
.save_button{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 180px;
}
.save_button button{
    width: 50px;
    height: 40px;
}