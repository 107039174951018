@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}

.topPartWhitening{
    width: 100%;
    height: 822px;
    position: relative;
    background-image: url(../../../../assets/img/Plombavorum_Main_Img.svg);
    display: flex;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;

}

.topPartWhitening::after{
    width: 100%;
    top: 0;
    height: 100%;
    content: url(../../../../assets/img/Rectangle\ 1\ \(1\).svg);
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;

}

.topPartWhitening::before{
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(140, 125, 153, 0.64);
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
}



.centerPartWhitening{
    width: 100%;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceTextWhitening{
    color: white;
    font-family: tahoma;
    font-weight: 700;
    font-size: 77px;
    width: 50%;
    text-transform: uppercase;
}

.serviceMiniText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    
    color: #FFFFFF;
    width: 100%;
}

.serviceMiniTextFlexWhitening{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}
.serviceMiniTextFlexWhitening span{
    margin-top: 36px;
    width: 100%;
}

.serviceButtonJoinWhitening{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 330px;
    height: 80px;
    border: none;
    margin-top: 49px;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #B498D2;

}

.secondSectionWhiteningMain{
    background-color: #FBFBFB;
    padding-bottom: 68px;
}
.secondSectionWhiteningMain2{
    background: rgba(245, 245, 245, 0.65);
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.13);
    padding-top: 56px;
    padding-bottom: 43px;
}
.secondSectionWhitening{
    margin-left: 192px;
}
.secondSectionWhitening h2{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    height: 95px;
    color: #626060;
}
.secondSectionWhitening span{
    margin-top: 35px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;

    color: #888888;

}
.mt{
    margin-top: 56px;
}
@media (max-width:1648px){
    .secondSectionWhitening{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        text-align: left;
    }

    .secondSectionWhitening span{
        width: 70%;
        align-self: center;
    }
    .secondSectionWhitening h2{
        font-size: 28px;
    }
    .secondSectionWhitening span{
        font-size: 28px;
    }
    .serviceTextWhitening{
        font-size: 54px;
        width: 100%;
    }
    .h2Flex{
        display: flex;
        width: 70%;
        justify-content: flex-start;
    }
}

@media (max-width:1001px){
    .topPartWhitening{
        background-image: none;
        background-color: #CDBEDA;
        height: 100%;
        padding-top: 45px;
        padding-bottom: 45px;
        margin-top: -45px;
    }
    .topPartWhitening::after{
        content: none;
    }
    .serviceMiniTextFlexWhitening{
        width: 97%;
    }
    .serviceMiniTextFlexWhitening{
        width: 90%;
        align-items: center;
    }
    .serviceMiniTextFlexWhitening span{
        width: 75%;
    }
    .serviceTextWhitening{
        color: white;
        font-family: tahoma;
        font-weight: 700;
        font-size: 77px;
        width: 84%;
        text-transform: uppercase;
    }
    .serviceTextWhitening{
        font-size: 53px;
        width: 75%;
    }
}
@media (max-width:724px){
    .serviceTextWhitening{
        font-size: 45px;
    }
}
@media (max-width:650px){
    .secondSectionWhitening{
        align-items: flex-start;
    }
    .h2Flex h2{
        margin-left: 30px;
        font-size: 35px;
        text-align: center;
        width: 100%;
    }
    .h2Flex {
        width: 100%;
    }
    .secondSectionWhitening span{
        align-self: flex-start;
        margin-left: 30px;
        width: 90%;
        text-align: center;
        font-size: 30px;
    }
    .serviceTextWhitening{
        font-size: 35px;
    }
}


@media(max-width:503px){
    .serviceMiniTextFlexWhitening{
        width: 95%;
        align-items: center;
    }
    .serviceMiniTextFlexWhitening span{
        text-align: center;
        width: 100%;
    }
    .serviceTextWhitening{
        width: 100%;
        text-align: center;
    }
}

@media (max-width:420px){
    .topPartWhitening{
        padding-top: -50px;
    }
    .serviceTextWhitening{
        font-size: 25px;
    }
    .serviceMiniTextFlexWhitening span{
        font-size: 25px;
    }
    .serviceButtonJoinWhitening{
        width: 250px;
        height: 70px;

    }
    .secondSectionWhitening span{
        margin-left: 10px;
        width: 100%;
    }
    .h2Flex h2{
        margin-left: 10px;
        width: 100%;
        font-size: 30px;
    }
    .secondSectionWhitening span{
        align-self: flex-start;
        margin-left: 15px;
        width: 90%;
        text-align: center;
        font-size: 25px;
    }
}