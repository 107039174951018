@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}

.topPartTeethHelping{
    width: 100%;
    height: 822px;
    position: relative;
    background-image: url(../../../../assets/img/whitening2.png);
    display: flex;
    z-index: 1;
    overflow-y: hidden;


}

.topPartTeethHelping::after{
    width: 100%;
    top: 0;
    height: 100%;
    content: url(../../../../assets/img/Frame\ 8.svg);
    position: absolute;
    overflow-x: hidden;
    overflow-y: hidden;

}

.topPartTeethHelping::before{
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(177, 153, 197, 0.64);
    position: absolute;
    overflow-y: hidden;
}


.centerPartTeethHelping{
    width: 81%;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    text-transform: uppercase;
    letter-spacing: 4.5px;
    color: #FFFFFF;
}

.serviceMiniText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    width: 50%;
    color: #FFFFFF; 
}

.serviceMiniTextFlex{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}
.serviceMiniTextFlex span{
    margin-top: 36px;
}

.serviceButtonJoin{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 330px;
    height: 80px;
    border: none;
    margin-top: 49px;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #B498D2;

}

.secondSectionTeethHelpingMain{
    background-color: #FBFBFB;
    padding-top: 92px;
    padding-bottom: 78px;
}

.secondSectionTeethHelping{
    margin-left: 192px;
    /* margin-top: 56px; */
    width: 80%;
}
.secondSectionTeethHelping h2{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    height: 95px;
    color: #626060;
}
.secondSectionTeethHelping span{
    margin-top: 35px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    width: 80%;
    color: #888888;
    

}

.secondSectionTeethHelpingMain2{
    background: rgba(245, 245, 245, 0.65);
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.13);
    padding-top: 56px;
    padding-bottom: 57px;
}

@media (max-width:1603px){
    .secondSectionTeethHelping{
        display: flex;
        margin-left: 0px;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .secondSectionTeethHelping span{
        font-size: 28px;
    }
    .secondSectionTeethHelping h2{
        font-size: 28px;
    }
    
}

@media (max-width:1230px){
    .topPartTeethHelping{
        background-image: none;
        background-color: #AEB2B2;
        height: 100%;
        padding-top: 45px;
        padding-bottom: 45px;
    }
    .topPartTeethHelping::after{
        content: none;
    }
    .centerPartTeethHelping{
        width: 100%;
    }
    .serviceMiniTextFlex span{
        width: 100%;
    }
}

@media (max-width:1001px){
    .topPartTeethHelping{
        margin-top: -45px;
    }
    .secondSectionTeethHelping h2{
        text-align: center;
    }
}

@media (max-width:769px){
    .secondSectionTeethHelping h2{
        height: 100%;
        width: 90%;
        text-align: center;
    }
    .secondSectionTeethHelping span{
        width: 90%;
        text-align: center;
    }
}

@media (max-width:491px){
    .serviceButtonJoin{
        width: 250px;
        height: 70px;
    }
    .serviceText{
        width: 100%;
        font-size: 35px;
    }
    .serviceMiniTextFlex span{
        font-size: 30px;
    }
    .secondSectionTeethHelping h2{
        font-size: 30px;
    }
    .secondSectionTeethHelping span{
        font-size: 25px;
    }
}

@media (max-width:340px){
    .serviceText{
        font-size: 32px;
    }
}