@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}



.serviceFooter{
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.serviceFooter h1{
    width: 50%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;

    color: #7F7298;
    margin-top: 105px;  
    letter-spacing: 1.5px;

}

.serviceFooter span{
    margin-top: 30px;
    width: 85%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #888888;
    padding-bottom: 45px;
}

@media (max-width:1601px){
    .serviceFooter h1{
        font-size: 57px;
    }
}

@media (max-width:1251px){
    .serviceFooter h1{
        width: 100%;
        font-size: 57px;
    }
}

@media (max-width:676px){
    .serviceFooter h1{
        font-size: 48px;
    }
    .serviceFooter span{
        font-size: 30px;
    }
}
@media (max-width:560px){
    .serviceFooter h1{
        font-size: 35px;
    }
}

@media (max-width:382px){
    .serviceFooter h1{
        font-size: 30px;
    }
    .serviceFooter span{
        font-size: 25px;
    }
}