.accordion_comp{
  display: flex;
  justify-content: space-between;
}

.accordion{
  list-style-type: none;
  padding-bottom: 50px;
  width: 55%;
  color: #535353;

  @media screen and ( max-width: 1008px){
    width: 100%;
  }
}

.accordion_item .button{
border: 1px solid #847299;
padding: 15px 20px;
font-size: 16px;
background-color: #fff;
margin-top: 20px;
justify-content: space-between;
display: flex;
}
.accordion_item.active .button {
    background-color: #847299;
    color: #fff;
  }

  .accordion_item .arrow {
    padding-top: 3px;
  }
  .answer_wrapper {
    height: 0;
    overflow: hidden;
  }
  .answer_wrapper.open {
    height: auto;
    border: 2px solid #847299;
    padding: 15px 20px;
  }
  .faqsImage{
    padding: 20px 0 0 30px;
    width: 45%;

    img{
      width: 92%;
      height: 86%;
      object-fit: cover;
    }
    @media screen and (max-width:1162px) {
      width: 86%;
      height: 80%;
    }
    @media screen and ( max-width: 1008px){
      display: none;
    }
  }