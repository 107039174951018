.services_part {
    background-color: #e4e1eb2d;
    padding-bottom: 50px;
    @media screen and (max-width: 470px){
      padding-bottom: 30px;
  }
    .boxes {
      display: flex;
      justify-content: space-between;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      flex-wrap: wrap;
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 30px;
      .box {
        padding: 60px 40px;
        height: 350px;
        background-color: #fff;
        border: 1px solid #eee;
        transition: all 0.4s;
        width: 33.333%;
        text-align: center;
        text-decoration: none;
        color: #191514;
        @media screen and (max-width: 930px){
          width: 50%;
        }
        @media screen and (max-width: 730px){
          padding: 10px;
          height: 210px;
        } 
          @media screen and (max-width: 470px){
            height: 170px;
        }
        @media screen and (max-width: 350px){
          width: 100%;
        }
        &:hover {
          background-color: #b5a8c5;
          h2 {
            color: transparent;
            transition: 0.2s;
          }
             h6 {
          color: #fff
        }
          .more {
            color: #fff;
           
            
          }
           
          .icon{
            img{
              -webkit-filter: invert(0);
                      filter: invert(0);
            }
          }
        }
        .icon{
          width: 80px;
          // margin-bottom: 10px;
          margin: 20px auto;
          @media screen and (max-width: 470px){
            width: 50px;
          }
          img{
            -webkit-filter: invert(70%);
                    filter: invert(70%);
          }
        }
        h6 {
          font-size: 23px;
          font-weight: 600;
          // letter-spacing: 1.3px;
          line-height: 1.4;
          padding-bottom: 30px;
        color: #3d3d3d;
        @media screen and (max-width: 730px){
          font-size: 17px;
          padding-bottom: 0;
        }
        @media screen and (max-width: 470px){
          font-size: 14px;
        }
        @media screen and (max-width: 400px){
          font-size: 12px;
        }
        }
      }
    }
  }
  
  