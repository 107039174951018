.main {
    padding-top: 152px;
    padding-bottom: 40px;
    @media screen and (max-width: 925px) {
      padding-top: 130px;
    }
    @media screen and (max-width: 425px) {
      padding-top: 111px;
    }
    &__head {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      background-color: #d8d8d8;
    }
    &__image {
      width: 40vw;
      height: 35vw;
      min-height: 450px;
      max-height: 550px;
      // max-height: 700px;
      margin:0  20px;
   
  
      @media screen and (max-width: 840px) {
        width: 90vw;
        height: 65vw;
        min-height: 450px;
        padding-bottom: 40px;
        margin: 0 auto;
      }
      img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center;
           object-position: center;
  
      }
    }
    &__content {
      display: flex;
      margin-top: 50px;
      @media screen and (max-width: 840px) {
        flex-wrap: wrap;
        margin-top: 30px;
      }
      .desc {
        width: 55vw;
        color: #212121;
        margin-left:  30px;
        
        @media screen and (max-width: 840px) {
          width: 100vw;
          margin-left: 0;
        }
        .title{
          text-align: left;
          justify-content: start;
          h1{
            color: rgb(53, 53, 53);
            font-size: 35px;
            font-weight: 700;
            @media screen and (max-width: 1080px) {
                font-size: 30px;
            }
            @media screen and (max-width: 470px) {
              font-size: 20px;
          }
          }
        }
        p {
          color: #8f8f8f;
          line-height: 1.5;
          margin: 0 auto;
          padding: 30px 0;
          font-size: 18px;
          text-align: left;
          @media screen and (max-width: 1080px) {
            font-size: 16px;
        }
        @media screen and (max-width: 470px) {
          font-size: 15px;
  
      }
        }
      }
    }
  }
  #more {
    display: none;
  }
  .btn {
    border: 2px solid #000;
    &:hover {
      color: #000;
      border: 2px solid #000;
      background: #f7f7f7;
    }
  }
  