@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  list-style-position: inside;
}
* {
  // font-family: ArmFont;
  // font-family: Helvetica, sans-serif;
  font-family: 'Roboto', sans-serif;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}
img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.head_section {
  padding-top: 152px;
  background: rgb(135,108,168);
  background: linear-gradient(90deg, rgba(135,108,168,1) 0%, rgba(194,169,224,1) 100%);
  @media screen and (max-width: 925px) {
    padding-top: 130px;
  }
  @media screen and (max-width: 425px) {
    padding-top: 111px;
  }
  .head_cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10px 0 30px;
  }
  .head_img {
    max-width: 500px;
   margin-bottom: -4px;
   margin-top: 10px;
  }
  .head_text {
    text-transform: uppercase;
    color: #fff;
    max-width: 600px;
    font-size: 53px;
    font-weight: 600;
    @media screen and (max-width: 1080px) {
      font-size: 44px;
    }
    @media screen and (max-width: 925px) {
      font-size: 34px;
      max-width: 500px;
    }
    @media screen and (max-width: 685px) {
      font-size: 27px;
    }
    @media screen and (max-width: 500px) {
      font-size: 19px;
    }
    @media screen and (max-width: 370px) {
      font-size: 14px;
    }
    @media screen and (max-width: 270px) {
      font-size: 10px;
    }
    .text{
      margin-bottom: 30px;
      font-size: 4vw;
      @media screen and (max-width: 370px) {
        margin-bottom: 0;
      }
    }
  }
  .button_btn {
    @media screen and (max-width: 370px) {
      display: none;
    }
        a {
          text-decoration: none;
          display: inline-block;
          padding: 13px 30px;
          transition: all 0.3s ease;
          border: 1px solid #fff;
          // border-radius: 10px;
          color: #fff;
          font-size: 21px;
          font-weight: 300;
          @media screen and (max-width: 925px) {
            font-size: 17px;
          }
          @media screen and (max-width: 768px) {
            padding: 13px;
               font-size: 15px;
          }
          @media screen and (max-width: 550px) {
            padding: 8px;
               font-size: 10px;
          }
          &:hover {
            background: #fff;
            color: #847299;
          }
        }
      }
}

.home_map {
  width: 100%;
  iframe {
    width: 100%;
    height: 500px;
    border: 0;
    @media screen and (max-width: 925px) {
      height: 400px;
    }
    @media screen and (max-width: 550px) {
      height: 300px;
    }
  }
}
.button > a {
  color: #f8f9fa;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  display: inline-block;
  padding: 13px 50px;
  transition: all 0.3s ease;
  border: 2px solid #000;
  background: #000;
  border-radius: 50px;
}

.button > a:hover {
  background: #fff;
  color: #000;
}
.title_bg {
  height: 200px;
  background-repeat: no-repeat;
  background-position: right;
  // background-blend-mode: overlay;
  background-color: #fbfbfb;
  background-size: cover;
  background-image: url(../src/assets/img/mercar.png);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 925px) {
    height: 150px;
  }
  @media screen and (max-width: 550px) {
    height: 80px;
  }
     
  @media screen and (max-width: 420px) {
    height: 73px;
  }
  h1 {
    font-size: 40px;
    font-weight: 400;
    // padding-bottom: 15px ;
    letter-spacing: 10px;
    text-transform: uppercase;
    padding: 0 30px;
    color: rgb(114, 114, 114);
    @media screen and (max-width: 1080px) {
      font-size: 35px;
    }
    @media screen and (max-width: 925px) {
      font-size: 30px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 550px) {
      font-size: 22px;
    }
    @media screen and (max-width: 420px) {
      font-size: 16px;
      padding: 0 10px;
    }
  }
}
.sec_title {
  margin-top: 8px;
  background-color: #847299;
  h6 {
    max-width: 1280px;
    color: #fff;
    font-weight: 500;
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 40px;
    padding: 35px 30px;
    @media screen and (max-width: 1280px) {
      padding: 35px 30px;
    }
    @media screen and (max-width: 750px) {
      font-size: 30px;
      letter-spacing: 1px;
    }
    @media screen and (max-width: 550px) {
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 30px;
      padding: 20px 30px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 376px) {
      font-size: 16px;
      letter-spacing: 0;
    }
  }
}
footer { 
  background-color: #847299;
  padding: 18px ;
  .footer2{
    text-align: center;
    color: rgb(252, 250, 250);
    font-family: Arial, Helvetica, sans-serif;
  }
  
} 

