.service-page{
    padding-top: 152px;
    @media screen and (max-width: 925px) {
        padding-top: 125px;
    }
    @media screen and (max-width: 425px) {
        padding-top: 111px;
    }
    .title_bg{
        margin-bottom: 80px;
        @media screen and (max-width: 1080px) {
            margin-bottom: 30px;
        }
    }
    
        .services_text{
            color: #8f8f8f;
            line-height: 1.5;
            // width: 80%;
            margin: 0 auto;
            padding: 80px 30px;
            font-size: 21px;
            text-align: center;
            @media screen and (max-width: 1080px) {
                font-size: 16px;
                padding: 30px;
            }
            @media screen and (max-width: 470px) {
              font-size: 15px;
              padding: 30px 0;
          }
        }
    

}
.service-wrap{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.description__card{
    position: relative;
    text-align: center;
    width: 35%;
    height: 250px;
    margin: 0 20px;
    cursor: pointer;
    @media screen and (max-width: 830px) {
        width: 60%;
    }
    @media screen and (max-width: 750px) {
        width: 80%;
    }
    @media screen and (max-width: 414px) {
        width: 100%;
        height: 220px;
    }
    &:hover .description__card-inner{
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transition: transform 1s;
        -webkit-transition: transform 1s;
        -moz-transition: transform 1s;
        -ms-transition: transform 1s;
        -o-transition: transform 1s;
    }
}
.description__card-inner{
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: transform 1s;
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
}
.description__card-face{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    // padding: 0 30px;
    .icon{
        // margin: 0 auto;
        width: 150px;
        height: 150px;
        @media screen and (max-width: 414px) {
            width: 100px;
            height: 100px;
        }
       img{
            -webkit-filter: invert(65%);
            filter: invert(65%);
}
    }
    .icon1{
        margin-left: 30px ;
    }

    h3{
        font-size: 18px;
        // font-weight: 300;
        // letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.74;
        color: rgb(63, 63, 63);
        margin-top: 25px;
        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
    ul{
        margin-left: 30px;
    }
    li{
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        text-align: left;
        list-style: circle;
        padding-bottom: 10px;
        @media screen and (max-width: 1080px) {
            font-size: 16px;
        }
        @media screen and (max-width: 470px) {
          font-size: 15px;
      }
    }
}

.back{
    // background: #A496B5;
    background-color: #b5a8c5;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    align-items: start;
}

.service_imageitems{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @media screen and (max-width: 1080px) {
        flex-wrap: wrap;
    }
    .item{
        width: 31%;
        flex-wrap: wrap;
        margin: 20px 10px;
        @media screen and (max-width: 1080px) {
            width: 47%;
        }
        @media screen and (max-width: 850px) {
            width: 70%;
        }
        @media screen and (max-width: 550px) {
            width: 100%;
            flex-wrap: wrap;
        }
        .image{
            height: 350px;
            img{
                -o-object-fit: cover;
                   object-fit: cover;

                @media screen and(max-width:550px){
                    width: 100%;
                }   
            }
        }
        h3{
            padding: 25px;
            text-align: center;
            background-color: #6147aa48;
        
            @media screen and (max-width: 650px) {
                font-size: 16px;
            }}
    }
}