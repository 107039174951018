.homeblog {
  padding-bottom: 70px;
  text-align: center;
  .blogelements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .homeblog_singlepost {
        width: 33%;
        padding: 0 15px;

        @media screen and ( max-width: 1008px){
          width: 100%;
        }
      .homeblog_post {
        color: #535353;
        text-decoration: none;
        -moz-text-decoration: none;
        line-height: 1.8;
        .homeblog_img {
          width: 100%;
          height: 230px;
          padding-bottom: 10px;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
  .blogelements.container{
    padding: 0 15px;
  }
}

.home_about_content{
  padding: 50px 30px;
  p {
    color: #535353;
    font-size: 16px;
    line-height: 1.6;
    padding: 3px;
    font-weight: 600;
  }
}