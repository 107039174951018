@font-face {
    font-family: tahoma;
    src: url(../../../../assets/fonts/tahoma.ttf);
}
.mainPlomb{
    width: 100%;
    height: 100%;
}
.topPartPlombFlex{
    width: 100%;
    min-height: 50%;
    height: 822px;
}
.topPartPlomb{
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    background-image: url(../../../../assets/img/plomb1.png);
    display: flex;
    z-index: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-y: hidden;


}
.fiveSectionSpan{
    margin-left: 36px;
}

.centerPart{
    width: 61%;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fiveSectionBlock1CenterContent span{
    margin-top: 36px;
}
.serviceText{
    color: white;
    font-family: tahoma;
    font-weight: 700;
    font-size: 77px;
    letter-spacing: 4.5px;
}

.serviceMiniText{
    font-family: tahoma;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;

    color: #FFFFFF;
    width: 76%;
}

.serviceMiniTextFlex{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 76%;
}

.serviceButtonJoin{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 330px;
    height: 80px;
    border: none;
    margin-top: 49px;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #B498D2;

}

.secondSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    background-color: #FAFAFA;

}

.secondSectionFirstText{
    width: 50%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 86px;
    text-align: center;
    color: #7F7298;
    margin-top: 41px;
    letter-spacing: 3.5px;

}
.secondSectionSecondText{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 58px;
    text-align: center;
    width: 75%;
    color: #C3ADD7;
}
/* .contactButtonPlomb{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #7F7298;

} */

.thirdSection{
    width: 100%;
    background-color: #FAFAFA;
    display: flex; 
    height: 400px;
    align-items: center;
    justify-content: space-around;
}
.thirdSectionBlocks{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.thirdSectionBlocks img{
    width: 86px;
    height: 86px;
}
.thirdSectionBlocks span{
    margin-top: 70px;
}
.thirdSection span{
    font-family: 'Tahoma';
    width: 70%;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-top: 25px;

    color: #717171;
}

.thirdSectionButtonFlex{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 150px;
    align-items: center;
    background-color: #FAFAFA;
}

.thirdSectionButtonFlex button{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    width: 28%;
    height: 80px;
    border: none;
    color: #7F7298;
    cursor: pointer;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-align: center;

    color: #7F7298;

}

.fourSection{
    display: flex;
    margin-top: 105px;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.fourSection h2{
    font-family: 'Tahoma';
    width: 55%;
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 86px;
    text-align: center;
    letter-spacing: 5.5px;

    color: #7F7298;
}
.fourSection span{
    width: 84%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #888888;
}

.fiveSection{
    margin-top: 59px;
    display: flex;
    justify-content: space-around;
    width: 100%;

}

.fiveSectionBlock1{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fiveSectionBlock1TopContent{
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.21);
    border-radius: 60px 60px 30px 30px;
    height: 21%;
    width: 78%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 3.5px;

    color: #7F7298;
}

.fiveSectionBlock1CenterContent{
    margin-top: 36px;
    display: flex;
    justify-content: center;
    background: #BFB0DB;
    align-items: center;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 22px -3px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    height: 479px;
    width: 630px;
    font-family: 'Tahoma';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 57px;

color: #FFFFFF;

}
.fiveSectionBlock2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
}

.fiveSectionBlock2TopContent{
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.21);
    border-radius: 60px 60px 30px 30px;
    height: 21%;
    width: 70%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 3.5px;

    color: #7F7298;
    margin-top: -36px;
}

.fiveSectionBlock2CenterContent{
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #BFB0DB;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 22px -3px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    height: 479px;
    width: 630px;
    font-family: 'Tahoma';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 57px;

color: #FFFFFF;

}

.sixSection{
    margin-top: 109px;
    width: 100%;
    display: flex;
    height: 556px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FAFAFA;

}

.sixSection h2{
    font-family: 'Tahoma';
    width: 50%;
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 93px;
    text-align: center;
    letter-spacing: 4.5px;
    color: #7F7298;
}
.sixSection span{
    margin-top: 25px;
    width: 85%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
    text-align: center;

    color: #888888;
}
.sevenSectionMainBlock{
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    height: 800px;
    align-items: center;
    justify-content: center;
}

.sevenSection{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 48%;
}

.sevenSectionPart1{
    display: flex;
    justify-content: center;
    margin-top: 77px;
    align-items: center;
}

.sevenSectionLeftBlock{
    width: 120px;
    height: 120px;   
    margin-left: -28px;
    display: flex;
}
.sevenSectionLeftBlockResponsive{
    width: 120px;
    height: 120px;   
    margin-left: -28px;
    display: none;   
}

.sevenSectionRigthBlock{
    display: flex;
    flex-direction: column;
    width: 56%;
    margin-left: 28px;

}

.sevenSectionRigthBlock h2{
    width: 50%;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 59px;
    letter-spacing: 4.5px;

    color: #7F7298;

}
.sevenSectionRigthBlock span{
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;

    color: #7F7298;
    width: 100%;
}

@media (max-width:1701px){
    .fiveSectionBlock1TopContent{
        font-size: 40px;
    }
    .fiveSectionBlock2TopContent{
        font-size: 40px;
    }
}

@media (max-width:1601px){
    .topPartPlombFlex{
        height: 600px;
    }
    .thirdSection span{
        font-size: 26px;
    }
    .fiveSectionBlock1CenterContent{
        justify-content: flex-start;
    }
    .fiveSectionBlock1CenterContent span{
        margin-left: 44px;
    }
    .fiveSection span{
        font-size: 35px;
    }
    .fiveSectionSpan{
        margin-left: 44px;
    }
    .sevenSectionRigthBlock span{
        font-size: 35px;
    }
    .fiveSection{
        flex-wrap: wrap;
    }
    .serviceText{
        font-size: 55px;
    }
    .fiveSectionBlock2TopContent{
        font-size: 32px;
        margin-top: 25px;
    }
    .fiveSectionBlock1TopContent{
        font-size: 32px;
    }
    .secondSectionFirstText{
        font-size: 38px;
        line-height: 50px;
        width: 100%;
    }
    .serviceText{
        font-size: 38px;
    }
    .serviceMiniText{
        font-size: 25px;
    }
    .secondSectionSecondText{
        font-size: 21px;
    }
    .fourSection h2{
        font-size: 38px;
        line-height: 50px;
    }
    .fourSection span{
        font-size: 23px;
    }
    .serviceButtonJoin{
        width: 230px;
        font-size: 25px;
        height: 60px;
    }
    .thirdSection span{
        font-size: 24px;
    }
    .thirdSectionButtonFlex button{
        background: #FFFFFF;
        box-shadow: 0px 6px 20px rgb(0 0 0 / 17%);
        border-radius: 11px;
        width: 13%;
        height: 55px;
        border: none;
        color: #7F7298;
        cursor: pointer;
        font-family: 'Tahoma';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 41px;
        text-align: center;
        color: #7F7298;
    }
    .sixSection h2{
        font-size: 38px;
        width: 100%;
    }
    .sixSection span{
        font-size: 25px;
    }
    .fourSection span{
        font-size: 25px;
    }
    .sevenSectionLeftBlock{
        width: 68px;
        height: 68px;
    }
    .fiveSectionBlock2{
        margin-top: -50px;
        width: 47%;
    }
    .sevenSectionRigthBlock h2{
        font-size: 35px;
    }
    .sevenSectionRigthBlock span{
        font-size: 27px;
    }
}


@media (max-width:1367px){
    .sevenSectionRigthBlock h2{
        font-size: 32px;
    }
    .sevenSectionRigthBlock span{
        font-size: 25px;
    }
    .fiveSectionBlock2{
        margin-top: -50px;
    }
}
@media (max-width:1324px){
    .fiveSectionBlock2{
        margin-top: 56px;
        width: 100%;
    }
}
@media (max-width:1255px){
    .sevenSection{
        flex-wrap: wrap;
        width: 72%;
        
    }
    .sevenSectionPart1{
        margin-top: 35px;
    }
    .sevenSectionMainBlock{
        height: 100%;
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .sevenSectionRigthBlock h2{
        font-size: 40px;
    }
    .sevenSectionRigthBlock span{
        font-size: 25px;
    }
    .fiveSectionBlock2TopContent{
        width: 65%;
    }
    .fiveSectionBlock1TopContent{
        width: 75%;
    }
    .thirdSection{
        width: 100%;
        display: flex;
        padding-top: 34px;
        height: 100%;
        /* margin-top: 234px; */
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .thirdSectionButtonFlex{
        height: 140px;
        /* padding-top: 165px; */
    }
    .thirdSectionBlocks{
        width: 100%;
        margin-top: 35px;
    }
    .sixSection{
        height: 100%;
    }
}

@media (max-width:1121px){
    .secondSectionFirstText{
        width: 91%;
    }
    .thirdSectionButtonFlex button{
        width: 80%;
    }
    .topPartPlomb{
        background-image: none;
        background-color: #CDAEE4;
        height: 100%;
        padding-top: 45px;
        padding-bottom: 45px;
    }
    .topPartPlombFlex{
        margin-top: -65px;
        padding-top: 45px;
        padding-bottom: 45px;
        height: 100%;
    }
    .serviceButtonJoin{
        margin-top: 24px;
    }
    .serviceMiniText{
        width: 100%;
    }
    .centerPart{
        width: 100%;
    }
}


@media (max-width:932px){
    .fourSection h2{
        width: 100%;
    }
}

@media (max-width:676px){
    .fiveSectionBlock1CenterContent{
        height: 100%;
        width: 90%;
        margin-top: 53px;
    }
    .fiveSectionBlock2CenterContent{
        height: 100%;
        width: 90%;
        margin-top: 53px;
    }
    .secondSectionFirstText{
        font-size: 38px;
        width: 100%;
    }
    .secondSectionSecondText{
        font-size: 30px;
    }
    .fourSection h2{
        font-size: 38px;
    }
    .sixSection h2{
        font-size: 38px;
    }
    .thirdSection span{
        width: 100%;
    }
    .fiveSectionBlock2TopContent{
        width: 90%;
        font-size: 40px;
    }
    .fiveSectionBlock1TopContent{
        width: 90%;
        font-size: 40px;
    }
    .fiveSectionSpan{
        margin-left: 7px;
        margin-top: 19px;
    }
    .fiveSection span{
        margin-left:33px;
    }
}


@media (max-width:560px){
    .sevenSectionLeftBlockResponsive{
        display: none;
    }
    .sevenSectionLeftBlock{
        display: none;
    }
    .sevenSectionPart1{
        align-items: center;
    }
    .sevenSectionRigthBlock{
        width: 100%;
        align-items: center;
    }
    .sevenSectionRigthBlock h2{
        width: 100%;
    }
    .sevenSectionTextBlock{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .mr{
        margin-right: 25px;
    }
    .serviceText{
        font-size: 38px;
    }
    .sixSection h2{
        font-size: 35px;
    }
    .secondSectionFirstText{
        font-size: 35px;
    }
    .fourSection h2{
        font-size: 35px;
    }
    .fiveSectionBlock1TopContent{
        font-size: 35px;
    }
    .fiveSectionBlock2TopContent{
        font-size: 35px;
    }
    .fiveSectionBlock1CenterContent span{
        font-size: 35px;
        margin-left: 25px;
    }
    .fiveSectionSpan{
        font-size: 35px;
        margin-left: 25px;
    }
    .serviceMiniTextFlex{
        width: 93%;
    }
    .secondSectionSecondText{
        width: 100%;
    }
}


@media (max-width:442px){
    .secondSectionSecondText{
        font-size: 30px;
    }
    .secondSectionFirstText{
        font-size: 29px;
    }
    .thirdSection span{
        font-size: 30px;
    }
    .thirdSectionButtonFlex button{
        font-size: 30px;
    }
    .fourSection h2{
        font-size: 29px;
    }
    .fourSection span{
        font-size: 30px;
        letter-spacing: 2px;
    }
    .sixSection h2{
        font-size: 27px;
        line-height: 40px;
    }
    .sixSection span{
        font-size: 30px;
    }
    .sevenSectionRigthBlock h2{
        font-size: 30px;
    }
    .topPartPlomb{
        margin-top: -23px;
    }
    .fiveSectionBlock2TopContent{
        /* line-height: 35px; */
        font-size: 31px;
    }
    .sevenSection{
        width: 100%;
    }
    .sevenSectionPart1{
        align-items: center;
        width: 100%;
        text-align: center;
    }
}

@media (max-width:382px){
    .secondSectionFirstText{
        font-size: 24px;
        line-height: 50px;
    }
    .fourSection{
        margin-top: 25px;
    }
    .fourSection span{
        font-size: 29px;
    }
    .fourSection h2{
        line-height: 50px;
    }
    .fiveSectionBlock1TopContent{
        font-size: 25px;
    }
    .fiveSectionBlock2TopContent{
        font-size: 25px;
        line-height: 41px;
        height: 100%;
        padding-bottom: 10px;
    }
    .fiveSectionBlock1CenterContent span{
        font-size: 30px;
        line-height: 34px;
    }
    .fiveSectionBlock2CenterContent span{
        font-size: 30px;
        line-height: 34px;
    }
    .serviceButtonJoin{
        width: 265px;
        font-size: 30px;
    }
    .serviceMiniText{
        font-size: 30px;
    }
    .serviceText{
        font-size: 32px;
    }
    .fourSection span{
        width: 100%;
        font-size: 25px;
    }
}