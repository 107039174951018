.slide_part{
    max-width: 1280px;
    margin: 40px auto;
    padding:0 30px;
    @media screen and (max-width: 1610px) {
        padding: 0 30px;
      }
    @media screen and (max-width: 550px) {
      margin: 10px 0;
      padding: 20px 0 40px;
    }
   
  }
    .single-slide {
      height: 300px;
      @media screen and (max-width: 470px) {
        height: 250px;
      }
      @media screen and (max-width: 380px) {
        height: 200px;
      }
      @media screen and (max-width: 290px) {
        height: 160px;
      }
      img {
        -o-object-fit: cover;
           object-fit: cover;
        border: 5px solid rgba(0, 0, 0, 0.555);
        padding: 5px;
      }
    }
    .swiper-pagination {
      margin-top: 70px;
    }
    .swiper-pagination-bullet-active {
      background: #6247aa;
    }